import React from 'react'
import styled from '@emotion/styled'
import { ForwardLink } from '../content'
import {
  Heading, List, ListItem, LinkUniversal,
} from '../ui'

const StyledLink = styled(LinkUniversal)`
  line-height: 1.8;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
`

const FeaturedLinkGroup = ({ title, links, url: groupUrl }) => (
  <>
    <Heading
      mb={1}
      as="h2"
      fontSize={3}
    >
      {title}
    </Heading>
    <List
      mx="-3px"
      flexWrap="wrap"
      flexDirection="row"
      alignItems="center"
    >
      {
        links.map(({ title: linkTitle, url }, i) => (
          <ListItem
            p="3px"
            key={`${linkTitle}${String(i)}`}
          >
            <StyledLink
              to={url}
              variant="primary"
            >
              {linkTitle}
              {i >= links.length - 1 ? '' : ','}
            </StyledLink>
          </ListItem>
        ))
      }
      {
        !!groupUrl && (
          <ListItem
            p="3px"
          >
            <LinkUniversal
              to={groupUrl}
              variant="primary"
            >
              <ForwardLink
                py={0}
                as="span"
              >
                Még több
              </ForwardLink>
            </LinkUniversal>
          </ListItem>
        )
      }
    </List>
  </>
)

export default FeaturedLinkGroup
