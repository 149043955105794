import React, { lazy } from 'react'
import { graphql } from 'gatsby'
import { LayoutSection } from '../components/layout'
import { PostList } from '../components/posts'
import { Pagination, Lazy } from '../components/content'
import { FeaturedLinks } from '../components/links'
import {
  Grid, GridItem, Box, Heading, Placeholder,
} from '../components/ui'
import { WidgetTodaysEvents, WidgetEventCalendar, SidebarHome } from '../components/sidebar'
import { SchemaWebsite, SchemaOrganization } from '../components/seo'

const PostCarousel = lazy(() => import('../components/posts/PostCarousel'))

const HomeTemplate = ({ data, pageContext }) => {
  const { galleryCategory, featuredLinkGroups } = data.wp
  const { nodes: latestPosts } = data.wp.latestPosts
  const { edges: galleryPosts } = data.wp.galleryPosts

  const firstFeaturedLinks = featuredLinkGroups.slice(0, 4)
  const restOfFeaturedLinks = featuredLinkGroups.slice(4)

  const firstPosts = latestPosts.slice(0, 6)
  const restOfPosts = latestPosts.slice(6)

  return (
    <>
      <SchemaWebsite />
      <SchemaOrganization />
      <Grid my={-3}>
        <GridItem
          py={3}
          order={0}
          width={[1, 1, 1 / 3]}
        >
          <FeaturedLinks groups={firstFeaturedLinks} />
        </GridItem>
        <GridItem
          py={3}
          order={[1, 1, 3]}
          width={[1, 1, 1 / 3]}
        >
          <WidgetTodaysEvents />
          <WidgetEventCalendar />
        </GridItem>
        <GridItem
          py={3}
          order={2}
          width={[1, 1, 1 / 3]}
        >
          <FeaturedLinks groups={restOfFeaturedLinks} />
        </GridItem>
      </Grid>
      <Grid py={3}>
        <GridItem
          margin="0 auto"
          width={[1, 1, 2 / 3]}
          maxWidth={[325, 'initial']}
        >
          <LayoutSection heading="Friss cikkek">
            <section>
              <PostList posts={firstPosts} />
            </section>
            <Lazy fallback={<Placeholder height={198} />}>
              <Box
                mb={4}
                mx={[-3, -3, 0]}
                as="section"
              >
                <Heading
                  as="h2"
                  my={2}
                  pl={[2, 2, 0]}
                  fontSize={3}
                >
                  {galleryCategory.name}
                </Heading>
                <PostCarousel posts={galleryPosts} />
              </Box>
            </Lazy>

            <section>
              <PostList posts={restOfPosts} />
            </section>

            <Pagination
              {...pageContext}
              nextText="Még több cikk"
            />
          </LayoutSection>
        </GridItem>
        <GridItem
          width={[1, 1, 1 / 3]}
        >
          <SidebarHome />
        </GridItem>
      </Grid>
    </>
  )
}

export default HomeTemplate

export const pageQuery = graphql`
  query IndexQuery($first: Int!) {
    wp {
      featuredLinkGroups {
        title
        url
        links {
          title
          url
        }
      }

      latestPosts: posts(
        first: $first
        where: {
          status: PUBLISH
          language: DEFAULT
          orderby: { field: DATE, order: DESC }
        }
      ) {
        nodes {
          ...PostListFields
          ...PostListImageFields
        }
      }

      galleryCategory: category(
        idType: SLUG
        id: "tisza-to-foto-video"
      ) {
        name
      }

      galleryPosts: posts(
        first: 10
        where: {
          status: PUBLISH
          language: DEFAULT
          orderby: { field: DATE, order: DESC }
          categoryName: "tisza-to-foto-video"
        }
      ) {
        edges {
          node {
            uri
            title
            featuredImage {
              node {
                src: sourceUrl(size: SMALL_POST)
                srcSet(size: SMALL_POST)
                mediaDetails {
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  }
`
