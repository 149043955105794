import React from 'react'
import Link from './Link'
import LinkExternalSafe from './LinkExternalSafe'

const LinkUniversal = ({ to, ...props }) => {
  const isInternalurl = /^\/(?!\/)/.test(to)

  return isInternalurl
    ? (
      <Link
        to={to}
        {...props}
      />
    )
    : (
      <LinkExternalSafe
        href={to}
        {...props}
      />
    )
}

export default LinkUniversal
