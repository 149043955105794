import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import WidgetAd from './WidgetAd'
import WidgetYoutube from './WidgetYoutube'
import WidgetLatestPosts from './WidgetLatestPosts'
import WidgetFutureEvents from './WidgetFutureEvents'

const SidebarHome = () => {
  const { wp: data } = useStaticQuery(graphql`
    query HomeSidebarQuery {
      wp {
        futureEvents: events(
          first: 5
          where: {
            scope: FUTURE
            status: PUBLISH
            language: DEFAULT
            orderby: {
              order: ASC
              field: START_DATE
            }
          }
        ) {
          nodes {
            ...EventFields
          }
        }

        latestPosts: posts(
          first: 5
          where: {
            status: PUBLISH
            language: DEFAULT
            orderby: {
              field: DATE
              order: DESC
            }
          }
        ) {
          nodes {
            ...PostFields
          }
        }
      }
    }
  `)

  return (
    <aside>
      <WidgetFutureEvents events={data.futureEvents.nodes} />
      <WidgetAd />
      <WidgetYoutube />
      <WidgetLatestPosts posts={data.latestPosts.nodes} />
    </aside>
  )
}

export default SidebarHome
