import React from 'react'
import { Box } from '../ui'
import FeaturedLinkGroup from './FeaturedLinkGroup'

const FeaturedLinks = ({ groups }) => (
  <Box my={-2}>
    {groups.map(({ title, links, url }, i) => (
      <Box
        py={2}
        as="section"
        key={String(`featured-link-group-${i}`)}
      >
        <FeaturedLinkGroup
          title={title}
          url={url}
          links={links}
        />
      </Box>
    ))}
  </Box>
)

export default FeaturedLinks
