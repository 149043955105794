import React from 'react'
import galite from 'ga-lite'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { LinkExternalSafe } from '../ui'
import { LayoutSection } from '../layout'

const WidgetAd = () => {
  const { wp } = useStaticQuery(graphql`
    {
      wp {
        adSpots {
          spotSidebar {
            title
            url
            image {
              src: sourceUrl
              sourceUrl
              mediaItemId: databaseId
              modified
              imageFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, width: 348)
                }
              }
            }
          }
        }
      }
    }
  `)

  const ads = wp.adSpots.spotSidebar
  const randomAd = ads[Math.floor(Math.random() * ads.length)]

  return randomAd
    ? (
      <LayoutSection>
        <LinkExternalSafe
          href={randomAd.url}
          onClick={() => {
            galite('send', 'event', 'AdClick', 'type', randomAd.url)
          }}
        >
          <GatsbyImage
            alt={randomAd.title}
            image={getImage(randomAd.image.imageFile)}
          />
        </LinkExternalSafe>
      </LayoutSection>
    ) : null
}

export default WidgetAd
